import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';

@Injectable()
export class FaqService {
  constructor(
    private apiService: ApiService
  ) { }

  postFaq(body: any, headers: any): Observable<any> {
    return this.apiService.post('/faq', body, headers);
  }

}
