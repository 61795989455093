import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ApiService {
  private api = environment.api_url;

  constructor(
    private http: HttpClient
  ) { }

  formatErrors(response: any): Observable<any> {
    return throwError(response.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(this.api + path, { params }).pipe(catchError(error => this.formatErrors(error)));
  }

  put(path: string, body: object): Observable<any> {
    return this.http.put(this.api + path, JSON.stringify(body)).pipe(catchError(error => this.formatErrors(error)));
  }

  post(path: string, body: object, headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.http.post(this.api + path, JSON.stringify(body), { headers }).pipe(catchError(error => this.formatErrors(error)));
  }

  delete(path): Observable<any> {
    return this.http.delete(this.api + path).pipe(catchError(error => this.formatErrors(error)));
  }
}
