import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

/*
* http://www.javascriptkit.com/javatutors/touchevents2.shtml
* */

@Directive({
  selector: '[appSwipe]'
})
export class AppSwipeDirective implements OnInit, OnDestroy {
  @Input() threshold = 50;     // Required min distance traveled to be considered swipe
  @Input() restraint = 25;     // Maximum distance allowed at the same time in perpendicular direction
  @Input() allowedTime = 300;  // Maximum time allowed to travel that distance

  @Output() swipeLeft = new EventEmitter();
  @Output() swipeRight = new EventEmitter();
  @Output() swipeUp = new EventEmitter();
  @Output() swipeDown = new EventEmitter();

  swipeDirection: string;

  swipeStartX: number;
  swipeStartY: number;

  swipeDistX: number;
  swipeDistY: number;

  swipeStartTime: number;
  swipeElapsedTime: number;

  touchStart$: Subscription;
  touchMove$: Subscription;
  touchEnd$: Subscription;

  constructor(
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.touchStart$ = fromEvent(this.el.nativeElement, 'touchstart').subscribe((e: TouchEvent) => {
      const { pageX, pageY } = e.changedTouches[0];

      this.swipeDirection = undefined;

      this.swipeStartX = pageX;
      this.swipeStartY = pageY;

      this.swipeStartTime = new Date().getTime();

      // e.preventDefault();
    });

    this.touchMove$ = fromEvent(this.el.nativeElement, 'touchmove').subscribe((e: TouchEvent) => {
      // e.preventDefault();
    });

    this.touchEnd$ = fromEvent(this.el.nativeElement, 'touchend').subscribe((e: TouchEvent) => {
      const { pageX, pageY } = e.changedTouches[0];

      this.swipeDistX = pageX - this.swipeStartX;
      this.swipeDistY = pageY - this.swipeStartY;

      this.swipeElapsedTime = new Date().getTime() - this.swipeStartTime;

      if (this.swipeElapsedTime <= this.allowedTime) {
        if (Math.abs(this.swipeDistX) >= this.threshold && Math.abs(this.swipeDistY) <= this.restraint) {
          this.swipeDirection = (this.swipeDistX < 0) ? 'swipeLeft' : 'swipeRight';
        } else if (Math.abs(this.swipeDistY) >= this.threshold && Math.abs(this.swipeDistX) <= this.restraint) {
          this.swipeDirection = (this.swipeDistY < 0) ? 'swipeUp' : 'swipeDown';
        }
      }

      if (this.swipeDirection) {
        this[this.swipeDirection].emit();
      }

      e.preventDefault();
    });
  }

  ngOnDestroy(): void {
    if (this.touchStart$) {
      this.touchStart$.unsubscribe();
    }

    if (this.touchMove$) {
      this.touchMove$.unsubscribe();
    }

    if (this.touchEnd$) {
      this.touchEnd$.unsubscribe();
    }
  }
}
