import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class PreOrderService {
  constructor(
    private apiService: ApiService
  ) { }

  postPreOrder(body: any): Observable<any> {
    return this.apiService.post('/assets/back.php', body);
  }

  postLater(body: any): Observable<any> {
    return this.apiService.post('/later', body);
  }
}
