import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';

import {
  AppSwipeDirective,
  AppVariableDirective,
  AppVisibilityDirective
} from './directives';

import {
  BoxComponent,
  CollapseComponent,
  FooterComponent,
  HeaderComponent,
  HiwComponent,
  PreOrderComponent,
  SliderComponent,
  SvgComponent
} from './layout';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxMaskModule.forRoot()
  ],
  declarations: [
    BoxComponent,
    CollapseComponent,
    FooterComponent,
    HeaderComponent,
    HiwComponent,
    PreOrderComponent,
    SliderComponent,
    SvgComponent,
    AppSwipeDirective,
    AppVariableDirective,
    AppVisibilityDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxMaskModule,
    BoxComponent,
    CollapseComponent,
    FooterComponent,
    HeaderComponent,
    HiwComponent,
    PreOrderComponent,
    SliderComponent,
    SvgComponent,
    AppSwipeDirective,
    AppVariableDirective,
    AppVisibilityDirective
  ]
})
export class SharedModule { }
